/* theme */
.subtheme-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.about-subtheme h3 {
  font-size: 1.5rem;
  text-transform: capitalize;
  margin-bottom: 40px;
  color: #fff;
}

.subtheme-title {
  color: #4d4d4d;
}
 
.subtheme-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;

}

.subtheme-icon {
  width: 50px;
  margin: 0 10px;
}


#highlight2023 {
  background-color: #FCAF26;
}

@media screen and (max-width: 780px) {
  .subtheme-group {
    grid-template-columns: 1fr 1fr;
  }
}

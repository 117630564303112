footer {
    padding: 40px 0;
    background-color: #0e0c38;
    position:static;
    bottom: 0;

}

.footer-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.footer-item-title {
  color: #FCAF26;
  font-weight: 600;
  margin-bottom: 10px;
}

.footer-logo {
  color: #FCAF26;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}

.footer-link-group {
  display: flex;
  flex-direction: column;
}

.footer-link-item {
  color: #fff;
  text-decoration: none;
  margin: 5px 0;
}

.footer-contact {
  color: #fff;
}
  
.footer-title {
    color: #fff;
    font-size: 1rem;
    text-align: center;
}
    
.footer-hr {
    border:1px solid #7dc4fd;
}

.footer-base {
    color: #7dc4fd;
    text-align: center;
}

@media screen and (max-width: 780px) {

  .footer-group,
  .footer-link-group {
    grid-template-columns: 1fr;
  }
}


.speaker-announce {
  text-align: center;
  color: var(--sec-color-);
  font-size: 2.5rem;
  font-weight: 700;
}
  
.speaker-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 60px 0;
}

#speakers {
  /* background-color: #f3f3f3; */
  margin-top: 30px;
}

.speaker-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  height: 100%;
}

.speaker-link:hover {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  cursor: pointer;
}

.speaker-title {
  color: var(--sec-color-);
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

.speaker-position {
  color:#48ff00;
  font-size: 0.8rem;
  text-align: center;
  margin: 0;
}

.speaker-company {
  color:#fff;
  font-size: 0.8rem;
  text-align: center;
  margin: 0;
  
}

.speaker-status {
  color:var(--sec-color-);
  font-size: 0.8rem;
  text-align: center;
}

@media screen and (max-width:760px) {
  .speaker-group {
    grid-template-columns: repeat(2, 1fr);
  }
  .speaker-title {
    font-size: 1rem;
  }

  .speaker-position {
    font-size: 0.6rem;
  }

  .speaker-image {
    width: 150px;
  }
}

@media screen and (max-width:375px) {
  .speaker-group {
    grid-template-columns: 1fr;
  }
  .speaker-image {
    width: 180px;
  }
}
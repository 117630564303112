.featured-speaker-wrapper {
    padding: 60px 0;
}

.featured-speaker-wrapper h2 {
    color: #fff;
    margin-bottom: 30px;
    text-align: center;
}

.more-speaker-button {
    display: block;
    margin-top: 40px;
    margin: 40px auto;
    text-decoration: none;
    background-color: var(--sec-color-);
    width: max-content;
    padding: 10px;
    color: var(--pri-color-);
    font-weight: 700;
    border-radius: 5px;
}

.featured-speaker-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}


@media screen and (max-width:760px) {
    .featured-speaker-group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width:375px) {
    .featured-speaker-group {
      grid-template-columns: 1fr;
    }
  }
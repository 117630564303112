/* header */
header {
  background: url('https://images.pexels.com/photos/681333/pexels-photo-681333.jpeg'), rgba(13,17,111,0.5);
  background-blend-mode: multiply;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding: 60px 0;
}


.anni-logo {
  width: 200px;
}

.header-item:nth-child(2) {
  justify-self: end;
}
  
.header-base-wrapper {
  background-color:#0f9a7e ;
  padding: 60px;
}

.event-company {
  color: #fff;
  font-weight: 700;
}

/* .yellow-accent {
  color: var(--sec-color-);
} */
  
.header-base-group {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: center;
  color: #fff;
  font-weight: 700;
}
  
.event-name {
  color: var(--sec-color-);
  font-weight: 700;
  font-size: 3rem;
  line-height: -1;
}
  
.event-theme {
  color: #fff;
  font-size: 1.8rem ;
  font-weight: 600;
  word-spacing: -2px;
}

.event-date,
.event-time,
.event-venue {
  letter-spacing: 1px;
}
  
.header-button {
  background-color: #fff;
  text-decoration: none;
  color:#000 ;
  width: 100%;
  display: block;
  margin: 5px;
  text-decoration: none;
  text-align: center;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 8px;
  border-radius: 5px;
}
.header-button:hover {
  background-color: var(--sec-color-);
}

.logo-text{
  color: #fff;
}

.header-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 8px;
}

/* on mobile */
@media screen and (max-width: 768px) {
  .header-buttons {
    grid-template-columns: 1fr;
  }
  .header-group {
    grid-template-columns: 1fr;
  }
  .header-base-group {
    grid-template-columns: 1fr;
  }

  .event-theme {
    text-align: center;
    font-size: 1.5rem;
  }
  .event-name {
    font-size: 2.5rem;
    text-align: center;
  }
  .event-company {
    text-align: center;
    color: #fff;
  }
  .header-item:nth-child(2) {
    justify-self: center;
  }

  .event-date,
  .event-time,
  .event-venue {
    text-align: center;
  }

  .header-base-wrapper {
    padding: 0;
  }

  .event-company {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

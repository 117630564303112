.form-group {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto;
    padding: 60px 0;
}

.form-firstname,
.form-lastname,
.form-email,
.form-phone,
.form-company,
.form-position {
    padding: 16px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid #fff;
}

.form-submit {
    background-color: var(--sec-color-);
    border: none;
    padding: 13px;
    font-weight: 700;
    font-size: 1rem;
}

.form-submit:hover {
    background-color: #c5eff8;
}

@media screen and (max-width:780px) {
    .form-group {
        width: 100%;
    }
}

.success-message {
    text-align: center;
    background-color: var(--acc-color-);
    color: #fff;
    padding: 10px;
    font-weight: 600;
    margin: 10px auto;
    width: max-content;
}

.radio-title {
    color: #fff;
    font-weight: 500;
    margin-bottom: 20px;
}

.radio-title div {
    margin-bottom: 16px;
}

.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    color: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

.container-radio:hover input ~ .checkmark {
    background-color: #ccc;
}

.container-radio input:checked ~ .checkmark {
    background-color: #2196F3;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-radio input:checked ~ .checkmark:after {
    display: block;
}

.container-radio .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
:root {
    --pri-color- : #1C174B;
    --sec-color- : #FCAF26;
    --acc-color-: #009d5e;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Box sizing rules */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favor of better control in authored CSS */
body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin-block-end: 0;
  margin-block-start: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'], ol[role='list'] {
  list-style: none;
  padding-inline-start: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  background: #124F6A;
}

body {
  /* background-image: url('./images/body-background.png'); */
  background-size: cover;
  background-position:center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2, h3, h4 {
  overflow-wrap: break-word;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: inherit;
}

/* Make images easier to work with */
img, picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button, textarea, select {
  font: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}
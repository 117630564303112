.ticket-section {
  background-color: #dcdcdc;
  padding: 40px 0;
}

.reda-ticket {
  padding: 40px 0;
}

.reda-ticket-intro {
  margin: 0 auto;
  width: 500px;
  text-align: center;
}

.reda-ticket-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  flex-basis: 0;
  gap: 10px;
}

.reda-ticket-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  width: max-content;
}

.reda-ticket h2 {
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}
.reda-ticket-intro {
  color: #fff;
  margin-bottom: 40px;
}

.reda-ticket-body {
  margin-bottom: 20px;
}

.reda-ticket-body p {
  margin-bottom: 8px;
}

.regular,
.standard,
.vip,
.six
 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 8px;
  color: var(--sec-color-);
}

.regular::before {
  content: 'Regular';
  display: block;
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: var(--pri-color-);
}
.standard::before {
  content: 'Standard';
  display: block;
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: var(--pri-color-);
}
.vip::before {
  content: 'VIP/REAL ESTATE SALESPERSON (RES)';
  display: block;
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: var(--pri-color-);
}
.six::before {
  content: 'Table for Six';
  display: block;
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: var(--pri-color-);
}

.reda-ticket-button {
  text-align: center;
  margin-top: auto;
  display: block;
  background-color: var(--sec-color-);
  padding: 10px;
  text-decoration: none;
  color: #000;
  font-weight: 700;
}

@media screen and (max-width:780px) {
  .reda-ticket-group {
    
  }
  .reda-ticket {
    padding: 20px;
  }

  .reda-ticket-intro {
    width: 100%;
  }
}
.section-header {
    background: url('https://img.freepik.com/free-photo/bird-s-eye-view-shanghai_1127-3910.jpg?'), #225c00ba;
    padding: 60px 0;
    background-blend-mode: multiply;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section-header h2 {
    font-weight: 700;
    text-align: center;
    color: #fff;
}

.about-reda-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    color: #4d4d4d;
    border-radius: 6px;
    padding-top: 60px;
}

.about-reda-item {
    line-height: 2.0;
    font-size: 0.8rem;
    margin-bottom: 20px;
    color: #fff;
    text-align: justify;
}

.about-point {
    margin-top: 10px;
}

.about-subtheme {
    margin-bottom: 40px;
}

.container-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 20px 0;
}

iframe {
    border-radius: 10px;
    width: 100%;
    height: 315px;
}

@media screen and (max-width: 780px) {
    .about-reda-group {
        grid-template-columns: 1fr;
    }
    .container-group {
        grid-template-columns: 1fr;
    }
    .about-reda-group {
        padding: 60px 20px;
    }
}

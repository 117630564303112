#awardcenter {
    background-color: black;
    background-image: url('../../images/awardbg.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}


.reda-award-group {
    padding: 60px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px;
}

.reda-award-item {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

}
   
.reda-award-title {
    font-weight: 700;
    margin-top: 10px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.reda-award-nominee div {
    color: #ffc107;
    margin: 10px 0;
}

@media screen and (max-width: 768px) {
    .reda-award-group {
        padding: 0 20px;
    }
}

.reda-vote-button {
    background-color: #fff;
    padding: 13px;
    width: max-content;
    display: block;
    margin: 10px 0;
    text-decoration: none;
    font-weight: 600;
    color: #000;
}


nav {
    height: 50px;
    width: 100%;
    position: relative;
}
  
.main-logo {
    text-decoration: none;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--sec-color-);
    padding: 10px 10px 10px 10px;
}

.nav-btn {
    display: none;
}

.link-group {
    display: inline;
    float: right;
    font-size: 1rem;
}

.link-item {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
}

.link-item:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

#nav-check {
    display: none;
}

@media (max-width:600px) {
nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
}
nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
}
nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
}
nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
}
nav > .link-group {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #00614ce1;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
}
.link-item {
    display: block;
    width: 100%;
}
nav > #nav-check:not(:checked) ~ .link-group {
    height: 0px;
}
nav > #nav-check:checked ~ .link-group {
    /* height: calc(100vh - 50px); */
    height: max-content;
    overflow-y: auto;
}
}
.orgainising-logo {
    padding: 60px 20px;
}

.orgainising-logo h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;

}

.partner-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.partner-group div {
    margin-bottom: 20px;
    color: #fff;
}

.partner-group img {
    margin-bottom: 40px;
 
}

.partner-group {
    margin-bottom: 20px;
    color: #fff;
}
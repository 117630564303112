.agenda-bg {
    background-color: #e2f4ff;
    padding: 20px 0;
  }

  .table-title {
    font-weight: 600;
    font-size: 0.8rem;
    width: 100%;
    color: var(--sec-color-);
    margin-bottom: 20px;
  }

  .table-content {
    font-weight: 300;
    font-size: 0.8rem;
  }

  .table-time {
    font-size: 0.8rem;
  }

  .day-1 {
    padding: 40px 0;
  }

  td {
    width: 1%;
  }
.topic {
  padding: 60px 0;
  text-align: center;
}

.topic h2 {
  color: #fff;
  margin-bottom: 30px;
}

/* .topic-group {
  display: flex;
  flex-direction: column;
} */

/* .table {
  background-color: x;
} */

.topic-item {
  background-color: #d3f6dc;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  margin: 10px auto;
  padding: 12px;
  width: 700px;
  border-bottom: 1px solid #fff;
}

.register-button {
  display: block;
  margin-top: 40px;
  margin: 40px auto;
  text-decoration: none;
  background-color: var(--sec-color-);
  width: max-content;
  padding: 10px;
  color: var(--pri-color-);
  font-weight: 700;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .topic-item {
    width: 100%;
  }
  .topic {
    padding: 0 20px 
  }
}
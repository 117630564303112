.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 16px;
  }
  
  .gallery-item {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  }
  
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

  }
  
  .gallery-item:hover .gallery-image {
    animation: scaleUp 0.3s forwards;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }
